import React from "react"
import Img from "gatsby-image"
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
    query {
      promiseOne: file(relativePath: {eq: "promise-1.png"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      promiseTwo: file(relativePath: {eq: "promise-2.png"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      promiseThree: file(relativePath: {eq: "promise-3.png"}) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
`

const ThreeStepsPage = ({ data }) => (
  <Layout>
    <SEO title="Slack Integration" />

    <div className="pageHero">
      <div className="container">
        <div className="row middle-sm">
          <div className="col-xs-12 center-xs col-sm-8 col-sm-offset-2">
              <h1 className="pageHeroTitle">Die 3 Schritte, um jetzt Homeoffice zu starten</h1>
              <p className="pageHeroTruncate">
                Es sind nur drei Schritte und Ihre Mitarbeiterinnen und
                Mitarbeiter können schon morgen wieder produktiv an Ihrer
                Überlebensstrategie mitarbeiten.
              </p>
              <p className="pageHeroTruncate">
                Homeoffice ist digitale Transformation im Kleinen. Statt sich in
                Meetingräumen zusammenzusetzen, nutzen Sie virtuelle Konferenzen,
                um Strategien zu besprechen, Konzepte und Präsentationen zu
                erarbeiten und - nicht zuletzt - mit Ihren Kunden in Kontakt zu
                treten. Die meisten Arbeiten, die Ihre Beschäftigten ansonsten im
                Büro erledigen, finden ohnehin meist schon am Rechner statt.
              </p>
          </div>
        </div>
      </div>
    </div>

    <div className="container section">
      <div className="row middle-sm">
        <div className="col-sm-5">
          <Img className="col-image" fluid={data.promiseOne.childImageSharp.fluid} />
        </div>
        <div className="col-sm-7">
          <h3 className="subHeadLine"><span className="questionLine">Frage 1</span> Desktop oder Laptop?</h3>
          <p>Wenn Ihre Mitarbeiterinnen und Mitarbeiter schon jetzt Laptops für
            ihre Arbeit nutzen, haben Sie schon eine wichtige Grundlage
            geschaffen: den Laptop kann man mit nach Hause nehmen und die
            benötigten Anwendungen sind bereits verfügbar.</p>
          <p>Wenn Sie noch keine Laptops oder andere mobile Endgeräte
            einsetzen, gilt es, die zu beschaffen und mit den benötigten
            Anwendungen auszustatten.</p>
          <p><strong>Wir unterstützen Sie bei der schnellen Beschaffung und Ausstattung von Endgeräten für Ihre Beschäftigten.</strong></p>
        </div>
      </div>
    </div>

    <div className="container section">
      <div className="row middle-sm">
        <div className="col-sm-7">
          <h3 className="subHeadLine"><span className="questionLine">Frage 2</span> Welche Software wird benötigt?</h3>
          <p>Die benötigte Software lässt sich grob in zwei Gruppen aufteilen:
        einmal ist es Spezialsoftware zur Erledigung bestimmter Aufgaben.
        Zum anderen ist das allgemeine Büro- und Kommunikationssoftware.</p>
          <p>Während die Möglichkeiten, schnell die Voraussetzungen für Homeoffice zu schaffen, bei Spezialsoftware sehr unterschiedlich sind, ist die Ausstattung mit Büro- und Kommunikationssoftware
        schnell zu regeln. Aber hat man die Grundlage einmal geschaffen,
        dann ist es sehr viel einfacher und effektiver, mit allen
        Betroffenen weitere Detailfragen zu klären und zu organisieren.</p>
        <p><strong>Wir beschaffen für Sie die notwendige Büro- und
          Kommunikationssoftware</strong></p>
        </div>
        <div className="col-sm-5">
          <Img className="col-image" fluid={data.promiseTwo.childImageSharp.fluid} />
        </div>
      </div>
    </div>

    <div className="container section">
      <div className="row middle-sm">
        <div className="col-sm-5">
          <Img className="col-image" fluid={data.promiseThree.childImageSharp.fluid} />
        </div>
        <div className="col-sm-7">
          <h3 className="subHeadLine"><span className="questionLine">Frage 3</span> Und was ist mit den Prozessen?</h3>
          <p>Soviel vorweg: wenn sie etablierte Prozesse im Unternehmen haben,
        ist das Gold wert. Wenn Sie zudem über die richtige Software
        verfügen, sind Prozesse kein Problem. Der Kernpunkt der Lösung
        liegt darin, mehr zu dokumentieren und Informationen transparent
        miteinander zu teilen.</p>
          <p>Und auch hier gilt: statt lange Strategieberatungen anzustoßen,
        vertrauen Sie auf die Intelligenz und die Kreativität Ihrer
        Beschäftigten. Einmal mit den richtigen Grundlagen ausgestattet,
        werden Ihre Mitarbeiterinnen und Mitarbeiter schnell die Vorteile
        digitaler Zusammenarbeit für sich entdecken.</p>
        <p><strong>Wir ermöglichen Ihre Beschäftigten einen schnellen Einstieg in
          digitaler Zusammenarbeit</strong></p>
        </div>
      </div>
    </div>

    <div className="pageHero sectionHero">
      <div className="container section">
        <div className="row">
          <div className="col-xs-12 center-xs col-sm-8 col-sm-offset-2">
            <h2 className="pageHeroTitle">Wir unterstützen Sie!</h2>
            <p>
            Können sie sich vorstellen, dass Sie in nur wenigen Tagen Ihr Unternehmen krisenfest machen können? Dann sollten Sie jetzt die richtigen Schritte unternehmen. Sie haben noch Zweifel? Dann sollten wir darüber sprechen. Abwarten ist keine Option, es geht um Ihr Unternehmen!
            </p>
            <br/>
            <Link to="/contact" className="button secondary">Kontaktieren Sie uns</Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ThreeStepsPage
